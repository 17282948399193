.orders-table {
  .orders-header {
    ul {
      margin: 0;
    }
  }
  td,
  th {
    //text-align: center;
    vertical-align: middle;
  }
  .order-action-btn {
    margin: 10px;
  }
}
