.subscription-item-block {
    .img-cont {
        height: 120px;
        overflow: hidden;
        text-align: center;
    }
    .pricing-column-title-box {
        height: 60px;
    }
}
