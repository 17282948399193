.orders-table {
    .orders-header {
        padding-top: 15px;
        ul {
            margin: 0;
        }
    }
    td, th {
        //text-align: center;
        vertical-align: middle;
    }
    .order-action-btn {
        margin: 10px;
    }
}
