.dropzone-field-cont {
  position: relative;

  .dropzone-field {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px dashed #cccccc;
    border-radius: 8px;
    cursor: pointer;
    background-color: #dedede;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 40px 40px;
    opacity: 0.75;
    overflow: hidden;

    .dropzone-field-text {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      padding: 32px 10px;
      height: 100%;
      color: #888;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover,
    &.hover {
      opacity: 1;
      border: 2px dashed black;
      background-color: tint-color(black, 60%);

      > * {
        color: black;
      }
    }

    input[type='file'] {
      position: absolute;
      top: -50px;
      height: 200%;
      left: -50px;
      width: 200%;
      opacity: 0.01;
    }
  }
  &.buttoned {
    .dropzone-field-btn-cont {
      height: 20%;
      //display: flex;
      //align-items: center;
      //justify-content: center;
    }
    .dropzone-field {
      height: 80%;
    }
  }
}
