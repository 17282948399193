.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.step-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.step-button.step-active {
  width: 50px;
  height: 50px;
}
.step-done {
}
.step-undone {
}
.step-item {
  z-index: 10;
  text-align: center;
}
.stepper-progress {
  -webkit-appearance: none;
  position: absolute;
  width: 95%;
  z-index: 5;
  height: 7px;
  margin-left: 18px;
  margin-bottom: 18px;
}
