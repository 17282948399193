.order-items-cont {
  .order-item {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-bottom: 10px;
    .order-item-action-btn {
      margin-left: 10px;
    }
  }
}
