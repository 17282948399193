.clfs-fi-cont {
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0 1rem 1rem 0;
    width: 10rem;
    display: inline-block;
    overflow: hidden;
    input[type="checkbox"] {
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .card-body {
        padding: 5px;
        .card-title {
            margin-bottom: 5px;
        }
        a.file-edit-icon {
            margin-right: 15px;
            > svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}

.clfs-fi-img-cont {
    display: flex;
    align-items: center;
    width: 100%;
    height: 128px;
    & > a, & > img {
        display: block;
        max-width: 90%;
        max-height: 118px;
        margin: 0 auto;
    }
    & > a > img {
        max-width: 100%;
        max-height: 110px;
    }
}

.clfs-transp {
    background-color: #f0f0f0;
    background-image: linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc),
    linear-gradient(45deg, #cccccc 25%, transparent 25%, transparent 75%, #cccccc 75%, #cccccc);
    background-size: 16px 16px;
    background-position: 0 0, 8px 8px;
    &.clfs-transp-8 {
        background-size: 8px 8px;
        background-position: 0 0, 4px 4px;
    }
}
.btn-upload {
    position: relative;
    display: block;
    overflow: hidden;

    input[type="file"] {
        position: absolute;
        top: -50px;
        height: 200%;
        left: -50px;
        width: 200%;
        opacity: .01;
    }
}


